import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/colors";
const androidImg = "/../images/Icons/google_play.png";
const iosImgWhite = "/../images/Icons/app_store_white.png";
const iosImgBlack = "/../images/Icons/app_store_black.png";

const BtnLinkToApp = ({ children, type, bgBtn }) => {
  return (
    <StyledBtn bgBtn={bgBtn}>
      <Content>
        <Icon
          src={
            type === "ios"
              ? bgBtn === colors.orangeLigth
                ? iosImgWhite
                : iosImgBlack
              : androidImg
          }
          type={type}
        />
        <ContentText>
          <TextType>
            {type === "ios" ? "Available on the" : "GET IN ON"}
          </TextType>
          <TextStore>{type === "ios" ? "App Store" : "Google Play"}</TextStore>
        </ContentText>
      </Content>
    </StyledBtn>
  );
};
export default BtnLinkToApp;

const StyledBtn = styled.button`
  background-color: ${({ bgBtn }) => bgBtn || "white"};
  border: 0;
  padding: 5px;
  padding-left: 8px;
  border-radius: 5px;
  width: 150px;
  z-index: 7;

  &:hover {
    opacity: 0.9;
    transform: scale(0.98);
  }
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  &:active {
    transform: scale(0.95); /* Reduce el tamaño cuando se hace clic */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Añade un efecto de sombra durante el clic */
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  // margin-top: 5;
`;
const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: flex-start;
`;
const TextType = styled.div`
  font-size: 8px;
  color: ${({ bgBtn }) => (bgBtn ? "white" : "black")};
  font-weight: 900;
`;
const TextStore = styled.div`
  font-size: 18px;
  color: ${({ bgBtn }) => (bgBtn ? "white" : "black")};
  font-weight: bold;
`;
const Icon = styled.img`
  height: ${({ type }) => (type === "ios" ? "28px" : "25px")};
  width: 25px;
`;
