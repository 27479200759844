import React from 'react';
import NavBarBasic from '../components/NavBar/NavBarBasic';
import { ContainerPageBlank, ContentPageBlank, H2PageBlank, H3PageBlank, ParagraphPageBlank, WraperPageBlank } from '../components/StyledComponents/StyledComponents';

const PrivacyPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <ContentPageBlank>
      <NavBarBasic />
      <WraperPageBlank>
        <ContainerPageBlank>
          <H2PageBlank>POLÍTICA DE PRIVACIDAD</H2PageBlank>
          <ParagraphPageBlank>
            El presente Política de Privacidad establece los términos en que PetMimo usa y protege la
            información que es proporcionada por sus usuarios al momento de utilizar la aplicación.
            Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando
            le pedimos llenar los campos de información personal con la cual usted pueda ser
            identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de
            este documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o
            ser actualizada por lo que le recomendamos revisarla continuamente para asegurarse que
            está de acuerdo con dichos cambios.
          </ParagraphPageBlank>
          <H3PageBlank>Información que es recogida</H3PageBlank>
          <ParagraphPageBlank>
            La aplicación solicitará cuando sea necesario, información personal como:
            Nombre,Apellidos, Sexo, Fecha de nacimiento, País, Estado, Ciudad, teléfono y dirección
            de correo electrónico. Así mismo cuando sea necesario podrá ser requerida la ubicación
            GPS precisa o aproximada. Solo son obligatorios el nombre y correo electrónico durante el
            registro en la aplicación. Esta información se utilizará para proveer mejores resultados
            durante el uso de la aplicación, al brindar una experiencia más personalizada.
          </ParagraphPageBlank>
          <H3PageBlank>Uso de la información recogida</H3PageBlank>
          <ParagraphPageBlank>
            PetMimo emplea la información con el fin de proporcionar una experiencia personalizada al
            usuario. Los datos como nombre, apellidos y correo electrónico a parte de utilizarse en el
            registro, pueden ser útiles para identificarte con nuestro equipo de soporte y son necesarios
            para identificarte de forma única en la comunidad que usará la aplicación. Tendremos en
            cuenta tu día de cumpleaños para brindarte notificaciones y para posibles ofertas o
            promociones de productos / servicios para ti y tu mascota. Los datos de tu ubicación
            relacionados a País, Estado, Ciudad y ubicación GPS serán utilizados para brindarte las
            mejores recomendaciones de productos y servicios cercanos a ti. Cuando lo autorices, tu
            ubicación GPS servirá para compartir la ubicación de una mascota perdida con su dueño,
            cuando escanees un QR asociado, o tomes una foto de ella y sea detectada por el sistema
            de reconocimiento facial.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            Es posible que sean enviados correos electrónicos periódicamente a través de nuestra
            plataforma con noticias importantes, ofertas especiales, nuevos productos y otra
            información que consideremos relevante y de tu beneficio. Estos correos electrónicos serán
            enviados a la dirección que proporciones y podrán ser cancelados en cualquier momento.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            PetMimo está altamente comprometido para cumplir con el compromiso de mantener su
            información segura. Usamos sistemas avanzados y los actualizamos constantemente para
            asegurarnos que no exista ningún acceso no autorizado.
          </ParagraphPageBlank>
          <H3PageBlank>Enlaces a Terceros</H3PageBlank>
          <ParagraphPageBlank>
            PetMimo pudiera contener enlaces a otros sitios que pudieran ser de su interés, a través de
            las publicaciones que realicen los usuarios en la red social. Una vez que des clic en algún
            enlace y abandones la App, PetMimo no tiene el control sobre al sitio al que es redirigido y
            por lo tanto no se responsabiliza de los términos o privacidad, ni de la protección de sus
            datos en esos otros sitios de terceros. Dichos sitios están sujetos a sus propias políticas de
            privacidad por lo cual es recomendable que los consultes para estar más informado.
          </ParagraphPageBlank>
          <H3PageBlank>Control de información personal</H3PageBlank>
          <ParagraphPageBlank>
            PetMimo no venderá, cederá, ni distribuirá la información personal que es recopilada sin tu
            consentimiento, salvo que sea requerido por un juez con un orden judicial.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en
            cualquier momento, con la correspondiente notificación
          </ParagraphPageBlank>
        </ContainerPageBlank>
      </WraperPageBlank>
    </ContentPageBlank>
  );
};

export default PrivacyPolicy;