import React from "react";
import PropTypes from "prop-types";
import Rombus from "./Image/Rombus";
import { colors } from "../utils/colors";
import Button from "./Buttons/Button";
import styled from "styled-components";

const ContentInfo = styled.div`
  margin: auto;
  margin-top: 80px;
  height: 100vh;
  width: 100%;
  justify-content: center;
  display: flex;
  gap: 3rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: none; /* Oculta el componente en dispositivos móviles */
  }
`;

const ContentTexts = styled.div`
  display: flex;
  flex-direction: column;
  //margin-left: 50px;
  width: 400px;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: 1.8em;
  font-weight: 700;
  color: black;
  width: 90%;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
`;

const Description = styled.div`
  font-size: 0.9em;
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.grayDark};
  width: 80%;
  //font-weight: lighter;
  line-height: 1.6;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 90%;
    text-align: center;
    margin-top: 15px;
  }
`;

const Info = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 0;
    margin-bottom: 20px;
    width: 95%;
    align-items: center;
  }
`;

const ContainerButton = styled.div`
  margin-top: 30px;
  width: 120px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 20px;
    width: auto;
  }
`;

const CardContainer = styled.div`
  display: none; /* Oculta el componente en pantallas grandes */

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    height: 680px;
    width: auto;
    flex-direction: column;
    border: 1px solid #ddd;
    padding: 10px;
    padding-top: 4rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;

const News = ({ title, description, image, url }) => {
  return (
    <>
      {/* Componente para escritorio */}
      <ContentInfo>
        <ContentTexts>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <ContainerButton>
            <Button
              text={"Saber mas"}
              onPress={() => {
                window.open(url, "_blank");
              }}
            />
          </ContainerButton>
        </ContentTexts>
        <Info>
          <Rombus imageSrc={image} colorLine={colors.orangeDark} />
        </Info>
      </ContentInfo>

      {/* Componente para móviles */}
      <CardContainer>
        <Info>
          <Rombus imageSrc={image} colorLine={colors.orangeDark} small />
        </Info>
        <ContentTexts>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <ContainerButton>
            <Button
              text={"Saber mas"}
              onPress={() => {
                window.open(url, "_blank");
              }}
            />
          </ContainerButton>
        </ContentTexts>
      </CardContainer>
    </>
  );
};

export default News;

News.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
