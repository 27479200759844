import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Buttons/Button";
import Feature from "./Features/Feature";
import ServiceSelected from "./Features/ServiceSelected";
import ListFeatures from "./Features/ListFeatures";

export default function BestFeatures() {
  const listFeature = [
    {
      id: 0,
      text: "Red social",
      image: "/images/BestFeatures/RedSocial-Icon.png",
      image_phone: "/images/BestFeatures/RedSocial-Pantalla.png",
      title: "Red social",
      description:
        "Contarás con una red social para compartir tus experiencias y aprender de otras personas. Tendrás ratos agradables de aprendizaje y entretenimiento. En caso de urgencia sobre cualquier tema, puedes recurrir a esta red para apoyarte en una comunidad de personas que aman a las mascotas.",
    },
    {
      id: 1,
      text: "Chat",
      image: "/images/BestFeatures/Chat-Icon.png",
      image_phone: "/images/BestFeatures/Chat-Pantalla.png",
      title: "Chat",
      description:
        "Tienes a disposición un chat privado para comunicarte con cualquiera en tu red.",
    },

    {
      id: 2,
      text: "Negocios",
      image: "/images/BestFeatures/Negocio-Icon.png",
      image_phone: "/images/BestFeatures/Negocio-Pantalla.png",
      title: "Negocios",
      description:
        "Si quieres servir al mundo de las mascotas abriendo un negocio, aquí también tienes espacio. Podrás registrar tu empresa, hacer que tus productos y servicios sean conocidos. Será fácil encontrarte y estar en contacto.",
    },
    {
      id: 3,
      text: "Perfil de las mascotas",
      image: "/images/BestFeatures/PerfilMascota-Icon.png",
      image_phone: "/images/BestFeatures/PerfilMascota-Pantalla.png",
      title: "Perfil de las mascotas",
      description:
        "Podrás llevar un registro de tus mascotas, conservando los datos más importantes  para cuando los necesites, estén a mano. Desde información básica como el nombre hasta documentos de salud que obtengas del veterinario.",
    },
    {
      id: 4,
      text: "Mapa",
      image: "/images/BestFeatures/Mapa-Icon.png",
      image_phone: "/images/BestFeatures/Mapa-Pantalla.png",
      title: "Mapa",
      description:
        "El mapa te ayudará a localizar cualquier lugar de interés entre ellos tiendas, peluquerías y veterinarias. También podrás encontrar eventos para reuniones de mascotas y unirte a ellos fácilmente",
    },
    {
      id: 5,
      text: "Buscador de Mascotas",
      image: "/images/BestFeatures/BuscarMascota-Icon.png",
      image_phone: "/images/BestFeatures/BuscarMascota-Pantalla.png",
      title: "Buscador de Mascotas",
      description:
        "Nuestro buscador te permite encontrar información sobre mascotas que estén en adopción, buscando pareja, perdidas, etc. No tendrás que publicar en otras redes sociales repetidamente, basta con que actualices el estado de la mascota y será visible a los demás.",
    },
    {
      id: 6,
      text: "Productos y Servicios",
      image: "/images/BestFeatures/BuscadorProducto-Icon.png",
      image_phone: "/images/BestFeatures/BuscadorProductoServicio-Pantalla.png",
      title: "Buscador de Productos y Servicios",
      description:
        "Este buscador te permite encontrar lo que necesitas para tu mascota, desde alimentos, juguetes, ropas y mucho más. Además puedes contactar con quién los comercializa.",
    },
    {
      id: 7,
      text: "Identificador QR",
      image: "/images/BestFeatures/QR-Icon.png",
      image_phone: "/images/BestFeatures/QR-Pantalla.png",
      title: "Identificador QR",
      description:
        "También te brindamos placa de identificación con código QR, que te será útil en caso de que pierdas tu mascota, al escanearla, tendrán tus datos de contacto y recibirás en tu teléfono la ubicación en el mapa donde fue encontrada la mascota",
    },
    {
      id: 8,
      text: "Reconocimiento facial",
      image: "/images/BestFeatures/ReconocimientoFacial-Icon.png",
      image_phone: "/images/BestFeatures/ReconocimientoFacial-Pantalla.png",
      title: "Reconocimiento facial",
      description:
        "En caso de que tu mascota se pierda y no esté identificada, desde PetMimo puedes usar el reconocimiento facial, cuando alguien suba una foto de ella, encontraremos la coincidencia, y te avisaremos en la app.",
    },
  ];
  const [featureSelected, setFeatureSelected] = useState(listFeature[0]);

  return (
    <Wrapper>
      <ImageHeader src="/images/Background/bg_pattern2.png" />
      <Container>
        <TitleSection>
          Las mejores funcionalidades en un solo lugar
        </TitleSection>
        <ServiceSelected feature={featureSelected} />
      </Container>
      <ListFeatures
        listFeature={listFeature}
        setFeatureSelected={setFeatureSelected}
        featureSelected={featureSelected}
      />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  height: auto; // altura fija para pantallas grandes
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: auto; // altura automática para pantallas pequeñas
    overflow-x: hidden;
  }
`;
const Container = styled.div`
  height: 50rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.creamPet};
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 63rem;
  }
`;
const ImageHeader = styled.img`
  width: 100%;
  height: 53rem;

  position: absolute;
  opacity: 0.1;
  margin-top: -1rem;
  object-fit: cover;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 65rem;
    margin-top: -1.1rem;
  }
`;
const TitleSection = styled.div`
  margin-top: 3rem;
  font-size: 1.8em;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayDark};

  //width: 60%;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`;
