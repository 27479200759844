import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { UnderConstructionImage, UnderConstructionText } from "../../components/StyledComponents/StyledComponents";

const UserManual = (props) => {
  
  // This component is the main page of the PetMimo User Manual
  // Replace the code below with the Manual content.

  const underConstructionImage = "/../images/underConstruction.png";

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} style={{backgroundColor: '#f5f8f9', height: '100vh', width: '100vw'}}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{height: '100%'}}
      >
        <Grid item>
          <UnderConstructionImage src={underConstructionImage} />          
        </Grid>
        <Grid item>
        <UnderConstructionText>Sitio en construcción</UnderConstructionText>          
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserManual;
