import React from 'react';
import NavBarBasic from '../components/NavBar/NavBarBasic';
import { ContainerPageBlank, ContentPageBlank, H2PageBlank, H3PageBlank, ParagraphPageBlank, WraperPageBlank } from '../components/StyledComponents/StyledComponents';

const AboutUs = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <ContentPageBlank>
      <NavBarBasic />
      <WraperPageBlank>
        <ContainerPageBlank>
          <H2PageBlank>Quienes somos</H2PageBlank>
          <ParagraphPageBlank>
            Somos un equipo comprometido y dedicado a brindar soluciones tecnológicas, que ayuden a los dueños a mejorar la salud, bienestar y seguridad de sus mascotas.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            En PetMimo buscamos que todas las personas interesadas puedan conectarse, compartir y comunicarse fácilmente, para crear comunidades que tengan como centro a las mascotas y su bienestar.
          </ParagraphPageBlank>
        </ContainerPageBlank>
      </WraperPageBlank>
    </ContentPageBlank>
  );
};

export default AboutUs;