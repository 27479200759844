import React from "react";
import styled from "styled-components";

import Feature from "./Feature";

export default function ListFeatures({
  listFeature,
  setFeatureSelected,
  featureSelected,
}) {
  return (
    <ListServices>
      {listFeature.map((item, index) => {
        return (
          <Feature
            item={item}
            isSelected={item.id === featureSelected.id}
            id={item.id}
            key={item.id}
            setFeatureSelected={setFeatureSelected}
          />
        );
      })}
    </ListServices>
  );
}
const ListServices = styled.div`
  height: auto;
  width: 100%;
  //flex: 1;
  display: flex;
  justify-content: center; /* Centra horizontalmente los elementos en la pantalla */
  overflow-x: auto;
  //white-space: nowrap; /* Evita el wrap de los elementos */
  gap: 10px;
  margin-top: -70px;
  padding-top: 20px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: flex-start;
  }

  ::-webkit-scrollbar {
    height: 10px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }

  /* Estilo opcional para mejorar la usabilidad en dispositivos táctiles */
  scroll-snap-type: x mandatory; /* Opcional, ayuda a que los elementos se alineen al deslizar */

  & > * {
    scroll-snap-align: center; /* Opcional, centra cada elemento cuando se detiene el deslizamiento */
    flex: 0 0 auto; /* Impide que los elementos internos se estiren o envuelvan */
    min-width: 100px;
  }
`;
