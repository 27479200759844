import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 25rem;
  height: 25rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: ${({ small }) => (small ? "16rem" : "25rem")};
    height: ${({ small }) => (small ? "16rem" : "25rem")};
  }
  @media (max-width: 930px) and (min-width: ${({ theme }) => theme.mobile}) {
    width: 20rem;
    height: 20rem;
    //border-radius: 100px;
  }
`;

const RombusContainer = styled.div`
  width: 25rem;
  height: 25rem;
  border-radius: 140px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: ${({ small }) => (small ? "16rem" : "25rem")};
    height: ${({ small }) => (small ? "16rem" : "25rem")};
    border-radius: 80px;
  }
  @media (max-width: 930px) and (min-width: ${({ theme }) => theme.mobile}) {
    width: 20rem;
    height: 20rem;
    border-radius: 100px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
  overflow: hidden;
  border: 0;
`;

const Image = styled.img`
  width: 120%;
  height: 120%;
  object-fit: cover;
  position: absolute;
  transform: rotate(-45deg);
`;

const OverlayRombus = styled.div`
  position: absolute;
  top: 0px;
  left: 4mm;
  width: 25rem;
  height: 25rem;
  border-radius: 140px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: ${({ small }) => (small ? "16rem" : "25rem")};
    height: ${({ small }) => (small ? "16rem" : "25rem")};
    border-radius: 80px;
  }
  @media (max-width: 930px) and (min-width: ${({ theme }) => theme.mobile}) {
    width: 20rem;
    height: 20rem;
    border-radius: 100px;
  }
  transform: rotate(45deg);
  border: 1px solid ${({ colorLine }) => colorLine || "white"}; /* Ajusta el color y el borde según tus necesidades */
  pointer-events: none;
  z-index: 10;
`;

const Rombus = ({ imageSrc, colorLine, small = false }) => (
  <Wrapper small={small}>
    <RombusContainer small={small}>
      <Image src={imageSrc} alt="Rombus" />
    </RombusContainer>
    <OverlayRombus colorLine={colorLine} small={small} />
  </Wrapper>
);

export default Rombus;
Rombus.propTypes = {
  imageSrc: PropTypes.string.isRequired, // url image to show
  colorLine: PropTypes.string, // OverlayRombus line color
  small: PropTypes.bool, // if true, the component will be smaller
};
