import React from "react";
import {
  DataFieldText,
  FieldContainer,
  LabelFieldText,
} from "../StyledComponents/StyledComponents";

const FieldView = (props) => {
  return (
    <FieldContainer>
      <LabelFieldText>{props.field}</LabelFieldText>
      <DataFieldText>{props.data}</DataFieldText>
    </FieldContainer>
  );
};

export default FieldView;
