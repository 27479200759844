import React from "react";
import styled from "styled-components";

export default function Button({ bg, text, colorText, onPress }) {
  return (
    <StyledBtn onClick={onPress} bg={bg}>
      <Text colorText={colorText}>{text}</Text>
    </StyledBtn>
  );
}
const StyledBtn = styled.button`
  background-color: ${({ bg, theme }) => bg || theme.colors.orangeLigth};
  border: 0;
  height: 35px;
  min-width: 120px;
  align-items: center;
  justify-content: center;
  z-index: 99;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  padding: 5px;
  border-radius: 8px;
  &:hover {
    opacity: 0.9;
    transform: scale(0.98);
  }
  &:active {
    transform: scale(0.95); /* Reduce el tamaño cuando se hace clic */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Añade un efecto de sombra durante el clic */
  }
`;
const Text = styled.a`
  font-size: 11px;
  color: ${({ colorText }) => colorText || "white"};
`;
