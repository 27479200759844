import styled, { keyframes } from "styled-components";
import { colors } from "../utils/colors";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid ${colors.orangeLigth};
  border-right: 2px solid ${colors.orangeLigth};
  border-bottom: 2px solid ${colors.orangeLigth};
  border-left: 4px solid ${colors.orangeDark};
  background: transparent;
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;

export default Loading;