import React from "react";
import styled from "styled-components";
import Button from "./Buttons/Button";
import CustomCarousel from "./Carousel/CustomCarousel";
import { colors } from "../utils/colors";
import CarouselOrganization from "./Carousel/CarouselOrganization";

const Organizations = () => {
  const organizations = getStaticOrganizations();
  return (
    <Wrap>
      <Spots>
        <Spot1 src="/images/Header/spot_left_header.png" />
        <Spot2 src="/images/Header/spot_right_header.png" />
      </Spots>
      <CarouselHeader>
        <CarouselHeaderText>
          Organizaciones de mascotas con las que trabajamos
        </CarouselHeaderText>
      </CarouselHeader>
      <ContentTemp>
        <Organization
          onClick={() => {
            window.open(organizations[0].url, "_blank");
          }}
        >
          <ImgWrapper>
            <Img src={organizations[0].img} />
          </ImgWrapper>
        </Organization>
      </ContentTemp>
      {/* <CarouselOrganization organizations={organizations} /> */}
      {/* <CustomCarousel
        listData={organizations}
        //indicator={true}
        //colorIndicator={colors.grayLigth}
      >
        {organizations?.map((item, index) => {
          return (
            <Organization key={index}>
              <ImgWrapper>
                <Img src={item.img} />
              </ImgWrapper>
            </Organization>
          );
        })}
      </CustomCarousel> */}
    </Wrap>
  );
};

export default Organizations;
const ContentTemp = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  // background-color: red;
`;

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    //margin-top: -90px;
    overflow-x: hidden;
  }
`;
const CarouselHeader = styled.div`
  height: 15em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  margin-bottom: 80px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 10px;
  }
  //background-color: #000000;
`;
const CarouselHeaderText = styled.p`
  font-size: 2em;
  color: black;
  padding-top: 40px;
  width: 500px;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-right: 50px;
    padding-left: 50px;
  }
`;
const Spots = styled.div`
  display: flex;
  //height: 100%;
  height: 110vh;
  z-index: 2;
  width: 100%;
  //top: 0;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 140%;
  }
`;
const Spot1 = styled.img`
  height: 350px;
  width: auto;
  opacity: 0.5;
  //align-self: flex-end;
  margin-top: -30px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    //margin-bottom: -100px;
    height: 170px;
  }
`;
const Spot2 = styled.img`
  height: 200px;
  width: auto;
  margin-top: 35px;
  opacity: 0.5;

  //align-self: flex-end;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    //margin-bottom: -100px;
    height: 140px;
  }
`;
const ContainerButton = styled.div`
  margin-top: 30px;
  width: 120px;
`;
const Organization = styled.div`
  height: auto;
  width: auto;
  margin-bottom: 70px;
  cursor: pointer;
  z-index: 2;
`;
const ImgWrapper = styled.div`
  height: 80px;
  width: 80px;
  //background-color: #db734a;
`;
const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
const getStaticOrganizations = () => {
  return [
    {
      category: "Rescate y refugio",
      img: "/images/Organizations/Ecqus_New.jpg",
      code: "001",
      name: "ECQUS REHABILITACIÓN ANIMAL",
      phone: "096 424 909",
      email: "org.paraanimales.opa@gmail.com",
      url: "https://ecqus.org/",
    },
  ];
};
