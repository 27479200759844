import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link as InternalLink } from "react-router-dom";
import { Link } from "react-scroll";
import { colors } from "../../utils/colors";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Buttons/Button";

const NavBarCoreLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("home");
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const handleSetActive = (to) => {
    setActiveItem(to);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth > 1111) {
      setIsOpen(false);
    }
  };

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setIsVisible(false);
      } else {
        // Scrolling up
        setIsVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    // window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", controlNavbar);

    return () => {
      // window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);
  return (
    <Navbar isVisible={isVisible}>
      <InternalLink to="/">
        <Logo src="/images/Icons/logo_white.png" />
      </InternalLink>

      <MenuIconWrap onClick={toggleMenu}>
        {!isOpen && <MenuIcon style={{ color: "white" }} />}
      </MenuIconWrap>
      <NavMenu isOpen={isOpen}>
        <MenuLogoWrapper>
          <InternalLink to="/">
            <MenuLogo src="/images/Icons/logo_white.png" />
          </InternalLink>
          <MenuIconWrap onClick={toggleMenu}>
            <CloseIcon style={{ color: "white" }} />
          </MenuIconWrap>
        </MenuLogoWrapper>
        <ButtonGroup>
          <MenuLink onClick={closeMenu}>
            <NavItem>
              <StyledLink
                to="footer"
                smooth={true}
                duration={500}
                spy={true}
                onSetActive={() => handleSetActive("footer")}
                active={activeItem === "footer"}
                offset={-80}
                className={activeItem === "footer" ? "active" : ""}
              >
                Contacto
              </StyledLink>
            </NavItem>
          </MenuLink>
          <MenuLink onClick={closeMenu}>
            <NavItem>
              <StyledLink
                to="people_opinion"
                smooth={true}
                duration={500}
                spy={true}
                onSetActive={() => handleSetActive("people_opinion")}
                active={activeItem === "people_opinion"}
                offset={-80}
                className={activeItem === "people_opinion" ? "active" : ""}
              >
                Opiniones
              </StyledLink>
            </NavItem>
          </MenuLink>
          <MenuLink onClick={closeMenu}>
            <NavItem>
              <StyledLink
                to="organizations"
                smooth={true}
                duration={500}
                spy={true}
                onSetActive={() => handleSetActive("organizations")}
                active={activeItem === "organizations"}
                offset={-80}
                className={activeItem === "organizations" ? "active" : ""}
              >
                Organizaciones
              </StyledLink>
            </NavItem>
          </MenuLink>
          <MenuLink onClick={closeMenu}>
            <NavItem>
              <StyledLink
                to="sabiasque"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                onSetActive={() => handleSetActive("sabiasque")}
                active={activeItem === "sabiasque"}
                className={activeItem === "sabiasque" ? "active" : ""}
              >
                Sabías que
              </StyledLink>
            </NavItem>
          </MenuLink>
          <MenuLink onClick={closeMenu}>
            <NavItem>
              <StyledLink
                to="features"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                onSetActive={() => handleSetActive("features")}
                active={activeItem === "features"}
                className={activeItem === "features" ? "active" : ""}
              >
                Features
              </StyledLink>
            </NavItem>
          </MenuLink>

          <MenuLink onClick={closeMenu}>
            <NavItem>
              <StyledLink
                to="home"
                smooth={true}
                duration={500}
                spy={true}
                offset={-80}
                onSetActive={() => handleSetActive("home")}
                active={activeItem === "home"}
                className={activeItem === "home" ? "active" : ""}
              >
                Home
              </StyledLink>
            </NavItem>
          </MenuLink>
        </ButtonGroup>
        {/* <ButtonGroup>
          <Button
            bg={"rgba(255, 255, 255, 0.5)"}
            text={"Iniciar sesión"}
            colorText={"black"}
            onClick={closeMenu}
          />
          <Button
            bg={"white"}
            text={"Crear cuenta"}
            colorText={"black"}
            onClick={closeMenu}
          />
        </ButtonGroup> */}
      </NavMenu>
    </Navbar>
  );
};

export default NavBarCoreLayout;

const Navbar = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 70px;
  position: fixed;
  overflow-y: hidden;
  top: 0;
  z-index: 99;
  background-color: /* transparent;  */ ${({ theme }) =>
    theme.colors.orangeDark};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: translateY(${({ isVisible }) => (isVisible ? "1" : "-100%")});
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 20px;
  }
`;
const NavItem = styled.div`
  position: relative;
`;
const Logo = styled.img`
  height: 40px;
  width: auto;
`;

const MenuIconWrap = styled.div`
  display: none;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 1111px) {
    display: block;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 1111px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.orangeDark};
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    z-index: 100;
  }
`;
const StyledLink = styled(Link)`
  color: ${({ theme, active }) =>
    active ? "white" : theme.colors.grayExtraLigth};
  font-weight: ${({ active }) => (active ? "500" : "300")};
  //font-weight: 600";
  text-decoration: none;
  font-size: 12px;
  padding: 10px 10px;
  cursor: pointer;
  /* &.active::after {
    content: "";
    position: absolute;
    bottom: -5px;

    left: 0;
    right: 0;
    height: 2px;
    background-color: white;
    border-radius: 25%;
  } */
`;
const MenuLogoWrapper = styled.div`
  display: none;
  @media (max-width: 1111px) {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const MenuLogo = styled.img`
  height: 40px;
  width: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 1111px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const MenuLink = styled.div`
  margin: 10px 0;
  font-weight: 100;
  font-size: 12px;
  color: white;
  //width: 100%;
  @media (min-width: 1111px) {
    margin: 0;
    color: inherit;
  }
`;
