export const colors = {
  blue: "#607d8b",
  green: "#8bc34a",
  clearBlue: "#03a9f4",
  female: "#ce55bf",
  male: "#3f51b5",
  orangeDark: "#C15810",
  orangeLigth: "#F7882F",
  yellowPet: "#F7C331",
  blueberryPet: "#6B7A8F",
  creamPet: "#DCC7AA",
  grayDark: "#333333",
  grayLigth: "#999999",
  grayExtraLigth: "#DDDDDD",
  error: "#c62828",
  success: "#00d03e",
  warningToast: "#e8e45b",
  errorToast: "#ff6961",
  downloading: "#84b6f4",
  petHeader: "#e5c8aa",
};

export const fonts = {
  megaHeader: "90px",
  megaHeader2: "50px",
  megaHeader3: "70px",
  titleHeader: "12px",
  defaultSize: "17px",
  titleSize: "20px",
  topSize: "25px",
  extraSize: "28px",

  // trying other styles
  generalText: "17px",
  minimText: "13px",
  mediumText: "15px",
  bigText: "30px",
  smallText: "10px",
};
