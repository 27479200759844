import React from 'react';
import NavBarBasic from '../components/NavBar/NavBarBasic';
import { ContainerPageBlank, ContentPageBlank, H2PageBlank, H3PageBlank, ParagraphPageBlank, WraperPageBlank } from '../components/StyledComponents/StyledComponents';

const WorkWithUs = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <ContentPageBlank>
      <NavBarBasic />
      <WraperPageBlank>
        <ContainerPageBlank>
          <H2PageBlank>Trabaja con nosotros</H2PageBlank>
          <ParagraphPageBlank>
            Si te interesa contribuir a mejorar la salud, el bienestar y seguridad de las mascotas, no dudes en ponerte en contacto con nosotros. Queremos sumar más personas a este esfuerzo, sin importar donde estés o cuanto tiempo tengas disponible.
          </ParagraphPageBlank>
        </ContainerPageBlank>
      </WraperPageBlank>
    </ContentPageBlank>
  );
};

export default WorkWithUs;