import React from "react";
import styled from "styled-components";

import PropTypes from "prop-types";
const Opinion = ({ initials, userName, sms, className }) => {
  return (
    <OpinionStyled className={className}>
      <CardSms>
        <User>
          <Initials>{initials}</Initials>
          <UserName>{userName}</UserName>
        </User>
        {sms}
      </CardSms>
    </OpinionStyled>
  );
};

export default Opinion;
Opinion.propTypes = {
  initials: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  sms: PropTypes.string.isRequired,
};
const OpinionStyled = styled.div`
  height: 200px;
  width: 250px;
  display: flex;
  margin: auto;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
  transition: transform 0.3s ease; /* Agregar transición */

&.active-opinion {
  transform: scale(1.1); /* Escalar al 110% cuando está activa */
}
`;
const User = styled.div`
  display: flex;
  align-items: flex-start;
 // background-color: red;
  width: 100%;
  margin-bottom: 15px;
`;

const Initials = styled.div`
  height: 35px;
  width: 35px;
  display: flex;
  //position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.grayLigth};
  color: white;
  font-weight: 100;
  z-index: 10;
`;
const CardSms = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  //position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color:white;/* ${({ theme }) => theme.colors.grayExtraLigth}; */
opacity: 0.8;
  margin-top: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 9;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  padding-top: 15px;
  color: ${({ theme }) => theme.colors.grayDark};

  
`;
const UserName = styled.div`
  text-align: center;
  //margin-top: 20px;
  align-self: center;
  font-size: 16px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.grayDark};


  
`;
