import React from "react";
import styled from "styled-components";
import Opinion from "./Opinion";
import CarouselOpinions from "../Carousel/CarouselOpinions";
import { ContainerAnyDisplay } from "../StyledComponents/StyledComponents";
const PeopleOpinion = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const listOpinion2 = [
    {
      id: 1,
      userName: "Ana Martinez",
      sms: "¡Increíble app! Me encanta la red social para mascotas, es como un Facebook solo para nuestros peludos amigos. Muy útil y divertido."
    },
    {
      id: 2,
      userName: "Juan Perez",
      sms: "La gestión de negocios es fantástica, me ha ayudado mucho a conectar con más clientes. ¡Además, las búsquedas en el mapa son muy precisas!"
    },
    {
      id: 3,
      userName: "Lucia Gomez",
      sms: "La función de búsqueda de productos y servicios es súper completa, puedo encontrar todo lo que necesito para mi mascota en un solo lugar."
    },
    {
      id: 4,
      userName: "Carlos Hernandez",
      sms: "El módulo de mensajería es excelente, me permite comunicarme fácilmente con otros dueños y negocios. ¡Muy recomendable!"
    },
    {
      id: 5,
      userName: "Mariana Lopez",
      sms: "La estrategia para mascotas perdidas es increíblemente útil. Saber que recibiré una notificación si encuentran a mi mascota me da mucha tranquilidad."
    },
    {
      id: 6,
      userName: "Pedro Garcia",
      sms: "La funcionalidad de ver productos y servicios de negocios en el mapa es genial. ¡Todo lo que necesito para mi mascota está a solo un clic de distancia!"
    },
    {
      id: 7,
      userName: "Sofia Ramirez",
      sms: "¡Excelente app! Me encanta poder ver los posts de otros usuarios en el home, es como tener un Facebook exclusivo para amantes de mascotas."
    },
    {
      id: 8,
      userName: "Fernando Lopez",
      sms: "La gestión de mascotas es muy completa, puedo llevar un control perfecto de todas las necesidades de mi perro. ¡Una herramienta imprescindible!"
    },
    {
      id: 9,
      userName: "Marta Fernandez",
      sms: "Me encanta cómo la app combina todo lo que necesito, desde buscar negocios hasta gestionar las necesidades de mis mascotas. ¡Muy práctica!"
    },
    {
      id: 10,
      userName: "Javier Alvarez",
      sms: "La idea del collar con QR es genial. Saber que recibiré un SMS si alguien encuentra a mi mascota me da mucha paz mental. ¡Gran idea!"
    }
  ];

  return (
    <Container>

      <ContainerAnyDisplay>
        <Section>
          <Name>Personas opinan</Name>
          <Line />
        </Section>
      </ContainerAnyDisplay>
      <ImgBg src="/images/Background/bg_pattern.png" />
      <ContainerAnyDisplay>
        <CarouselOpinions opinions={listOpinion2} indicator />
      </ContainerAnyDisplay>
    </Container >
  );
};

export default PeopleOpinion;

const Container = styled.div`
  display: flex;
  height: 35rem;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  padding-bottom: 0;
  padding-top: 60px;
  background-color: ${({ theme }) => theme.colors.yellowPet};
  @media (max-width: ${({ theme }) => theme.mobile}) {
  height: 35rem;
    
}
`;
const ImgBg = styled.img`
  width: 100%;
  height: 35rem;
  margin-top: -60px;
  object-fit: cover;
  position: absolute;
  opacity: 0.05;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    
}
`;
const Section = styled.div`
  align-self: flex-start;
  width: 100%;
  display: flex;
  flex-direction: column;
  //padding-bottom: 20px;
  flex:1;
  margin-bottom: 3rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content:flex-start;
    width: 100%;
}
`;
const Name = styled.h2`
  font-size: 18px;
  font-weight: 800;
  margin-left: 120px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-left: 0;
   align-self: center;
  }
  `;
const Line = styled.div`
  height: 3px;
  width: 130px;
  margin-left: 202px;
  background-color: ${({ theme }) => theme.colors.orangeDark};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-left: 0;
   align-self: center;
  width: 90px;
  }
  `;
