import React from "react";
import { Routes, Route } from "react-router-dom";
/* import Login from "./routes/Login";
import SignUp from "./routes/SignUp";
import CoreLayout from "./routes/CoreLayout";
import NoMatch from "./routes/NoMatch";
import Home from "./components/Home";
import AboutUs from "./routes/AboutUs";
import PrivacyPolicy from "./routes/PrivacyPolicy";*/
import OurServices from "./routes/OurServices";
import WorkWithUs from "./routes/WorkWithUs";
import TermsAndConditions from "./routes/TermsAndConditions";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import PetPublicProfile from "./routes/PetPublicProfile/PetPublicProfile";
import { ActionRequest } from "./utils/actionRequest";
import { LANGUAGES, METHODS } from "./utils/constants";
import _ from "lodash";
import appContext from "./providers/app/AppContext";
import { storage } from "./firebase";
import { ref, getDownloadURL } from "firebase/storage";
import UnderConstruction from "./routes/UnderConstruction/UnderConstruction";
import CacheBuster from "./CacheBuster";
import UserManual from "./routes/UserManual/UserManual";
import DeleteAccount from "./routes/DeleteAccount";
import CoreLayout from "./routes/CoreLayout";
import Home from "./components/Home";
import { ThemeProvider } from "styled-components";
import { colors } from "./utils/colors";
import AboutUs from "./routes/AboutUs";
import OurProducts from "./routes/OurProducts";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

const App = () => {
  //const [language, setLanguage] = useState(LANGUAGES.es);
  const language = LANGUAGES.es;

  const signOut = () => {};

  const axiosRequest = (
    url,
    method = METHODS.GET,
    then,
    body,
    onFailure = null
  ) => {
    ActionRequest(
      true,
      false,
      language,
      url,
      method,
      then,
      signOut,
      body,
      (e) => {
        if (_.isFunction(onFailure)) {
          onFailure(e);
        } else {
          console.log("error: axiosRequest ", e);
        }
      }
    );
  };

  const axiosRequestApiKey = (
    url,
    method = METHODS.GET,
    then,
    body,
    onFailure = null
  ) => {
    ActionRequest(
      false,
      true,
      language,
      url,
      method,
      then,
      signOut,
      body,
      (e) => {
        if (_.isFunction(onFailure)) {
          onFailure(e);
        } else {
          console.log("error:axiosRequestApiKey ", e);
        }
      }
    );
  };

  const axiosRequestNoToken = (
    url,
    method = METHODS.GET,
    then,
    body,
    onFailure = null
  ) => {
    ActionRequest(
      false,
      false,
      language,
      url,
      method,
      then,
      signOut,
      body,
      (e) => {
        if (_.isFunction(onFailure)) {
          onFailure(e);
        } else {
          console.log("error:axiosRequestNoToken ", e);
        }
      }
    );
  };

  const getFileFromObjectStorage = async (url, callback) => {
    if (url) {
      let newUrl = url.substring(21);
      console.log("newUrl: ", newUrl);
      getDownloadURL(ref(storage, newUrl))
        .then((urlFirebase) => {
          // `url` is the download URL for 'newUrl'
          if (callback) {
            callback(urlFirebase);
          }
        })
        .catch((error) => {
          console.log("parece que hay un error");
          console.log(error);
        });
    }
  };
  const theme = {
    colors: {
      orangeDark: colors.orangeDark,
      orangeLigth: colors.orangeLigth,
      blueberryPet: colors.blueberryPet,
      blue: colors.blue,
      green: colors.green,
      clearBlue: colors.clearBlue,
      female: colors.female,
      male: colors.male,
      yellowPet: colors.yellowPet,
      creamPet: colors.creamPet,
      grayDark: colors.grayDark,
      grayLigth: colors.grayLigth,
      grayExtraLigth: colors.grayExtraLigth,
      error: colors.error,
      success: colors.success,
      warningToast: colors.warningToast,
      errorToast: colors.errorToast,
      downloading: colors.downloading,
      petHeader: colors.petHeader,
    },
    mobile: "768px",
  };
  return (
    <CacheBuster>
      <div>
        <appContext.Provider
          value={{
            axiosRequest: axiosRequest,
            axiosRequestApiKey: axiosRequestApiKey,
            axiosRequestNoToken: axiosRequestNoToken,
            getFileFromObjectStorage: getFileFromObjectStorage,
          }}
        >
          <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/" element={<CoreLayout />} />
              {/* <Route path="/" element={<UnderConstruction />} /> */}
              <Route path="aboutUs" element={<AboutUs />} />
              <Route path="workWithUs" element={<WorkWithUs />} />
              <Route path="ourServices" element={<OurServices />} />
              <Route path="ourProducts" element={<OurProducts />} />
              {/*<Route path="/" element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="signUp" element={<SignUp />} />
            <Route path="privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="termsAndConditions" element={<TermsAndConditions />} />
            <Route path="*" element={<NoMatch />} />
          </Route> */}
              <Route path="/pet/:petId" element={<PetPublicProfile />} />
              <Route path="/petmimo-user-manual" element={<UserManual />} />
              <Route path="privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/delete-account" element={<DeleteAccount />} />
              <Route
                path="termsAndConditions"
                element={<TermsAndConditions />}
              />
            </Routes>
          </ThemeProvider>
        </appContext.Provider>
      </div>
    </CacheBuster>
  );
};

export default App;
