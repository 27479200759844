import styled from "styled-components";

import React from "react";

export default function ServiceSelected({ feature }) {
  const { title, description, image_phone } = feature;

  return (
    <ContentInfo>
      <ContentImage src={image_phone} />
      <ContentTexts>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentTexts>
    </ContentInfo>
  );
}

const ContentInfo = styled.div`
  margin-top: 3rem;
  height: 100vh;
  justify-content: space-evenly;
  display: flex;
  z-index: 9;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
`;
const ContentImage = styled.img`
  height: 600px;
  width: 300px;
  //background-color: yellow;
  border-radius: 30px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 30px;
  }
`;
const ContentTexts = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  width: 400px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 100px;
    //margin-right: 50px;
    align-items: center;
    padding-right: 50px;
    width: 100%;
  }
`;
const Title = styled.div`
  font-size: 1.8em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.grayDark};

  width: 60%;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`;
const Description = styled.div`
  font-size: 0.9em;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.grayDark};
  width: 100%;
  //font-weight: lighter;
  line-height: 1.6;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    text-align: justify;
  }
`;
