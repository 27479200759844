// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
//import { getFirestore } from "firebase/firestore"
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAR3FxZl6nk7UFObKe76eEqInpupfGMyKg",
  authDomain: "petmimo-3c661.firebaseapp.com",
  projectId: "petmimo-3c661",
  storageBucket: "petmimo-3c661.appspot.com",
  messagingSenderId: "574782438826",
  appId: "1:574782438826:web:42ad38c0eda91a60b0ffd3",
  measurementId: "G-89KQ1VDEV3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
//const db = getFirestore(app)
// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app, 'gs://petmimo-storage/');

export {storage};