import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { LogoImage } from "../../components/StyledComponents/StyledComponents";

const Loading = (props) => {
  const logo = "/../images/icon.gif";

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} style={{backgroundImage: "linear-gradient(to bottom right, #FF6800, #FFB700)", height: '100vh', width: '100vw'}}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{height: '100%'}}
      >
        <Grid item>
          <LogoImage src={logo} />          
        </Grid>
      </Grid>
    </Box>
  );
};

export default Loading;
