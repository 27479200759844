import styled from "styled-components";
import { colors, fonts } from "../../utils/colors";

export const PetImage = styled.div`
  width: 100vw;
  //height: 60vh;
  height: 350px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) =>
    props.src ? props.src : "/../images/Profile/background-pet.jpg"});
  display: flex;
  padding: 50px;
  justify-content: space-between;
`;

export const RoundedContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: auto;
  margin-top: -70px;
  border-radius: 25px 25px 0px 0px;
`;

export const MarginContainer = styled.div`
  margin: 20px;
`;

export const PetTitleContainer = styled.div`
  margin: 0px;
`;

export const PetTitle = styled.p`
  font-size: ${fonts.defaultSize};
  font-weight: bold;
  color: #333333;
  margin-top: 10px;
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -5px;
`;

export const LocationImage = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;
  width: 30px;
  height: 30px;
  tint-color: ${colors.grayLigth};
  z-index: 5;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/../images/Icons/Mapa.png");
`;

export const LocationText = styled.span`
  color: ${colors.grayLigth};
`;

export const StateContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const StateLabel = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  elevation: 5px;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 15px;
  background-color: ${colors.petHeader};
  margin-top: 5px;
  margin-right: 10px;
  padding: 5px 15px;
  height: 24px;
`;

export const StateText = styled.p`
  font-size: ${fonts.mediumText};
  color: ${colors.grayDark};
`;

export const Divisor = styled.div`
  border-bottom: 1px solid ${colors.grayExtraLigth};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const LostMessageText = styled.p`
  color: ${colors.grayDark};
  font-size: ${fonts.mediumText};
  text-align: justify;
`;

export const LabelFieldText = styled.p`
  color: ${colors.grayLigth};
  font-size: ${fonts.mediumText};
  text-align: justify;
`;

export const DataFieldText = styled.p`
  color: ${colors.grayDark};
  font-size: ${fonts.mediumText};
`;

export const FieldContainer = styled.div`
  margin-top: 5px;
`;

export const FieldSpaceBetweenContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PhoneImage = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  tint-color: ${colors.orangeLigth};
  background-image: url("/../images/Icons/LlamadaLost.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const UnderConstructionImage = styled.div`
  width: 350px;
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) =>
    props.src ? props.src : "/../images/underConstruction.png"});
`;

export const UnderConstructionText = styled.p`
  font-size: ${fonts.bigText};
  color: ${colors.grayDark};
`;

export const LogoImage = styled.div`
  width: 250px;
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) =>
    props.src ? props.src : "/../images/logo.gif"});
`;
export const ContainerAnyDisplay = styled.div`
  height: auto;
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
`;
export const ContentPageBlank = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
`;
export const WraperPageBlank = styled.main`
  background-color: #f5f5f5;
  padding: 100px 25%;

  @media (max-width: 1830px) {
    //margin-top: 7rem;
    padding: 7rem 10%; /* Reduce el padding en dispositivos móviles */
  }

  @media (max-width: 480px) {
    padding: 7rem 5%; /* Aún menos padding en pantallas muy pequeñas */
  }
`;
export const ContainerPageBlank = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 30px;
  color: #333333;
  text-align: justify;
`;
export const H2PageBlank = styled.h2`
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1.8em;
  text-transform: uppercase;
`;
export const H3PageBlank = styled.h3``;
export const ParagraphPageBlank = styled.p`
  font-size: 0.9em;
  line-height: 1.6;
`;
export const Ul = styled.ul``;
export const Li = styled.li`
  margin-left: 20px;
`;
