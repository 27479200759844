import axios from 'axios';
import { API_KEY } from './config';
import {FACEBOOK_TOKEN_KEY, GOOGLE_TOKEN_KEY, SOCIAL_PHOTO, LANGUAGES, METHODS, PETMIMO_TOKEN_KEY} from './constants';
import {getSecureValueFor, deleteSecureItem} from './secureStorage';

const tokenHeader = async () => {
  let token = await getSecureValueFor(PETMIMO_TOKEN_KEY);
  return token ? token : '';
};

const headerJson = async(useToken = true, useApiKey = false, language = LANGUAGES.en) => {
  if (useToken) {
    let token = await tokenHeader();
    return {headers: {
      'Content-type': 'application/json',
      'Accept-Language': language,
      Authorization: 'Token ' + token,
    }};
  }
  else if (useApiKey) {
    let apiKey = API_KEY;
    return {headers: {
      'Content-type': 'application/json',
      'Accept-Language': language,
      Authorization: 'Api-Key ' + apiKey,
    }};
  }
  else {
    return {headers: {
      'Content-type': 'application/json',
      'Accept-Language': language,
    }};
  }
};

const headerMultipart = (useToken = true, useApiKey = false, language = LANGUAGES.en) => {
  if (useToken) {
    return {headers: {
      'Content-type': 'multipart/form-data',
      'Accept-Language': language,
      Authorization: 'Token ' + tokenHeader(),
    }};
  }
  else if (useApiKey) {
    let apiKey = API_KEY;
    return {headers: {
      'Content-type': 'multipart/form-data',
      'Accept-Language': language,
      Authorization: 'Api-Key ' + apiKey,
    }};
  }
  else {
    return {headers: {
      'Content-type': 'multipart/form-data',
      'Accept-Language': language,
    }};
  }
};

const getHeadersJSON = async(useToken = true, useApiKey = false, language = LANGUAGES.en) => {
  let headers = await headerJson(useToken, useApiKey, language);
  return headers;
};

const getHeadersMultipart = (useToken = true, useApiKey = false, language = LANGUAGES.en) => {
  let headers = headerMultipart(useToken, useApiKey, language);
  return headers;
};

export const ActionRequest = (
  useToken = true,
  useApiKey = false,
  language = LANGUAGES.en,
  url,
  method = METHODS.GET,
  then,
  logOut,
  sendData,
  failure,
) => {
  let data;
  switch (method) {
    case METHODS.POST:
      data = postRequest(useToken, useApiKey, language, url, then, sendData, failure, logOut);
      break;
    case METHODS.PUT:
      data = putRequest(useToken, useApiKey, language, url, then, sendData, logOut);
      break;
    case METHODS.DELETE:
      data = deleteRequest(useToken, useApiKey, language, url, then, logOut);
      break;
    case METHODS.GET:
      data = getRequest(useToken, useApiKey, language, url, then, failure, logOut);
      break;
    case METHODS.UPLOAD:
      data = uploadRequest(useToken, useApiKey, language, url, then, sendData, logOut);
      break;
    case METHODS.PATCH:
      data = patchRequest(useToken, useApiKey, language, url, then, sendData, logOut);
      break;
    default:
      data = getRequest(useToken, useApiKey, language, url, then, failure, logOut);
  }
  return data;
};

const getRequest = async(useToken = true, useApiKey = false, language = LANGUAGES.en, url, afterFunction, failureFunction, logOut) => {
  let HEADERS = await getHeadersJSON(useToken, useApiKey, language);
  console.log(HEADERS);
  return axios
    .get(url, HEADERS)
    .then(res => {
      let data = res.data;
      if (afterFunction) {
        data = res.data._embedded ? res.data._embedded : res.data;
        const page = res.data.page ? res.data.page : [];
        afterFunction({data, page: page});
      }
      return data;
    })
    .catch(e => handleCatch(e, failureFunction, logOut));
};

const postRequest = async (useToken = true, useApiKey = false, language = LANGUAGES.en, url, afterFunction, data, failureFunction, logOut) => {
  let HEADERS = await getHeadersJSON(useToken, useApiKey, language);
  console.log(HEADERS);
  return axios
    .post(url, data, HEADERS)
    .then(res => {
      if (afterFunction) {
        afterFunction(res.data);
      }
      return res.data;
    })
    .catch(e => handleCatch(e, failureFunction, logOut));
};
const patchRequest = async (useToken = true, useApiKey = false, language = LANGUAGES.en, url, afterFunction, data, failureFunction, logOut) => {
  let HEADERS = await getHeadersJSON(useToken, useApiKey, language);
  console.log(HEADERS);
  return axios
    .patch(url, data, HEADERS)
    .then(res => {
      if (afterFunction) {
        afterFunction(res.data);
      }
      return res.data;
    })
    .catch(e => handleCatch(e, failureFunction, logOut));
};

const putRequest = async (useToken = true, useApiKey = false, language = LANGUAGES.en, url, afterFunction, data, failureFunction, logOut) => {
  let HEADERS = await getHeadersJSON(useToken, useApiKey, language);
  return axios
    .put(url, data, HEADERS)
    .then(res => {
      if (afterFunction) {
        afterFunction(res.data);
      }
    })
    .catch(e => handleCatch(e, failureFunction, logOut));
};

const deleteRequest = async (useToken = true, useApiKey = false, language = LANGUAGES.en, url, afterFunction, failureFunction, logOut) => {
  let HEADERS = await getHeadersJSON(useToken, useApiKey, language);
  return axios
    .delete(url, HEADERS)
    .then(res => {
      if (afterFunction) {
        afterFunction(res.data);
      }
    })
    .catch(e => handleCatch(e, failureFunction, logOut));
};

const uploadRequest = async (useToken = true, useApiKey = false, language = LANGUAGES.en, url, afterFunction, data, failureFunction, logOut) => {
  let HEADERS = await getHeadersMultipart(useToken, useApiKey, language);
  return axios
    .post(url, data, HEADERS)
    .then(res => {
      if (afterFunction) {
        afterFunction(res.data);
      }
    })
    .catch(e => handleCatch(e, failureFunction, logOut));
};

const handleCatch = (error, failureFunction, logOut) => {
  if (error.message === 'Network Error') {
    console.log('Network Error');
  } else {
    if (error.response) {
      if (error.response.status && error.response.status === 401) {
        console.log('401 Received...');
        console.log(error.response);
        outApp(logOut);
      }
      if (error.response.data) {
        if (error.response.data.message) {
          console.log(error.response.data.message);
        } else {
          console.log(error.response.data);
        }
      }
    }
  }
  if (failureFunction) {
    if (error.response && error.response.data) {
      failureFunction(error.response.data);
    }
    else {
      failureFunction(error);
    }
  }
};

const outApp = logOut => {
  deleteSecureItem(PETMIMO_TOKEN_KEY);
  deleteSecureItem(GOOGLE_TOKEN_KEY);
  deleteSecureItem(FACEBOOK_TOKEN_KEY);
  deleteSecureItem(SOCIAL_PHOTO);
  if (logOut) {
    logOut();
  }
};
