import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
const ContainerService = styled.div`
  height: 150px;
  width: 140px;
  flex-direction: column;
  display: flex;
  align-items: center;
  align-content: flex-start;
  z-index: 9;
  transform: ${(props) => (props.isSelected ? "translateY(-10px)" : "none")};
  transition: transform 0.3s ease-in-out;
  &:hover {
    //opacity: 0.9;
    transform: ${(props) =>
      props.isSelected ? "translateY(-10px)" : "translateY(-5px)"};
    //transform: scale(1.02);
  }
`;
const Card = styled.div`
  border-radius: 20px;
  height: 100px;
  width: 100px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Text = styled.div`
  margin-top: 10px;
  font-size: 0.9em;
  width: 90%;
  text-align: center;
  flex: 1;
`;
const Image = styled.img`
  width: 60%;
  height: 60%;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
const Feature = ({ isSelected, setFeatureSelected, item }) => {
  const onClick = () => {
    setFeatureSelected(item);
  };
  return (
    <ContainerService onClick={onClick} isSelected={isSelected}>
      <Card>
        <Image src={item.image} />
      </Card>
      <Text>{item.text}</Text>
    </ContainerService>
  );
};

export default Feature;
Feature.propTypes = {
  //text: PropTypes.string.isRequired, // url image to show
  //image: PropTypes.string.isRequired, // OverlayRombus line color
  isSelected: PropTypes.bool, // OverlayRombus line color
  setFeatureSelected: PropTypes.func,
  item: PropTypes.object.isRequired,
};
