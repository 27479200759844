//import SInfo from 'react-native-sensitive-info';
//import { KEYCHAIN_SERVICE_NAME, SHARED_PREFERENCES_NAME } from './constants';

export const setSecureItem = async (key, value) => {
  /* await SInfo.setItem(key, value, {
    sharedPreferencesName: SHARED_PREFERENCES_NAME,
    keychainService: KEYCHAIN_SERVICE_NAME,
  }); */
};

export const getSecureValueFor = async key => {
  /* let result = await SInfo.getItem(key, {
    sharedPreferencesName: SHARED_PREFERENCES_NAME,
    keychainService: KEYCHAIN_SERVICE_NAME,
  });
  if (result) {
    return result;
  } else {
    console.log(`No secure value found for ${key}.`);
    return null;
  } */
};

export const deleteSecureItem = async key => {
  /* await SInfo.deleteItem(key, {
    sharedPreferencesName: SHARED_PREFERENCES_NAME,
    keychainService: KEYCHAIN_SERVICE_NAME,
  }); */
};
