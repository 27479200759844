import React from 'react'
import PropTypes from "prop-types";

import BtnLinkToApp from '../Buttons/BtnLinkToApp';
import styled from 'styled-components';
import AppleLogo from '../../assets/svg/AppleLogo';
import AndroidLogo from '../../assets/svg/AndroidLogo';

const DownloadOurApp = ({ bgBtn, textColor }) => {
  return (
    <ContainerLinkApp >
      <DescriptionLink textColor={textColor}>Descarga nuestra aplicación aqui</DescriptionLink>
      <LinksToApps>
        <AppleLogo bgBtn={bgBtn} />
        <AndroidLogo bgBtn={bgBtn} />
      </LinksToApps>
    </ContainerLinkApp>
  )
}
export default DownloadOurApp
DownloadOurApp.propTypes = {
  bgBtn: PropTypes.string,
  textColor: PropTypes.string
}
const DescriptionLink = styled.div`
  font-weight: lighter;
  line-height: 1.6;

  font-size: 0.7em;
  color: ${({ theme, textColor }) => textColor || theme.colors.orangeLigth};
  @media (max-width: ${({ theme }) => theme.mobile}) {
  margin-top: 1.5rem;
    

  }
`;
const ContainerLinkApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    align-self: center;
    align-items: center;
  margin-right: 0;

  }
`;
const LinksToApps = styled.div`
  display: flex;
  width: 17rem;
  margin-top: 10px;
  justify-content: space-between;
`;