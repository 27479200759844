import React from 'react';
import packageJson from '../package.json';
import {interval} from 'rxjs';
global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

class CacheBuster extends React.Component {
  state = {
    loading: true,
    isLatestVersion: false,
  };

  componentDidMount() {
    this.compareAppVersion();
    this.checkAppVersion();
  }

  componentWillUnmount() {
    this.stopCheckAppVersion();
  };

  checkAppVersion = () => {
    const stream = interval(1000*30);
    this.subscription = stream.subscribe(() => {
      this.compareAppVersion();
    });
  };

  compareAppVersion = () => {
    let url = `/meta.json?${new Date().getTime()}`;
    fetch(url, { cache: 'no-cache' })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false }, () => {
            this.refreshCacheAndReload();
          });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  };

  stopCheckAppVersion = () => {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  };

  refreshCacheAndReload = () => {
      console.log('Clearing cache and hard reloading...')
      if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
  };

  render() {
    return this.props.children;
  }
}

export default CacheBuster;
