import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Rombus from "./Image/Rombus";
import NavBarCoreLayout from "./NavBar/NavBarCoreLayout";
import DownloadOurApp from "./CoreLayout/DownloadOurApp";
import { ContainerAnyDisplay } from "./StyledComponents/StyledComponents";
const ImageRombus = "/images/Header/golden-retriever-4292254_1280.jpg";

function Header() {
  //const [show, setShow] = useState(false);
  const showInput = () => {};
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    // Función para actualizar la altura del contenedor
    const updateHeight = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.offsetHeight);
      }
    };

    // Obtener la altura inicial
    updateHeight();

    // Escuchar cambios de tamaño de la ventana
    window.addEventListener("resize", updateHeight);

    // Limpiar el event listener al desmontar
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  return (
    <Container ref={containerRef}>
      <NavBarCoreLayout />
      <BackgroundImage
        src="/images/Background/bg_pattern.png"
        alt="Fondo"
        containerHeight={containerHeight}
      />
      <Spots containerHeight={containerHeight}>
        <Spot1 src="/images/Header/spot_left_header.png" />
        <Spot2 src="/images/Header/spot_right_header.png" />
      </Spots>
      <ContainerAnyDisplay>
        <ContentHeader>
          <Wrap>
            <Info>
              <Title2>Soluciones para </Title2>
              <Title>Dueños de Mascotas y Negocios</Title>
              <Description>
                Descubre como nuestra aplicación simplifica el cuidado de tus
                mascotas, brindando productos y servicios que mejoran su calidad
                de vida
              </Description>
            </Info>
            <WrapRombus>
              <Rombus imageSrc={ImageRombus} small />
            </WrapRombus>
          </Wrap>
          <Wrap>
            {/* <DownloadOurApp textColor={"white"} bgBtn={"white"} /> */}
            <SpaceEmpty />
          </Wrap>
        </ContentHeader>
      </ContainerAnyDisplay>
    </Container>
  );
}

export default Header;

const Container = styled.div`
  height: auto;
  width: 100%;
  padding-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.orangeDark};
`;
const SpaceEmpty = styled.div`
  height: 3rem;
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.4rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
  }
`;

const ContentHeader = styled.div`
  padding-left: 6rem;
  padding-right: 8rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 40px;
  }
`;
const Info = styled.div`
  margin-top: 5rem;
  top: 36rem;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: center;
    align-items: center;
  }
`;
const WrapRombus = styled(Info)`
  margin-right: 3rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    //margin-left: -10px;
    margin-top: 1rem;
    margin-right: 1rem;
  }
`;

const Title = styled.div`
  font-size: 2em;
  font-weight: 700;
  color: white;
  //width: 70%;
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    margin-top: 0;
  }
`;
const Title2 = styled(Title)`
  margin-top: 3rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 0;
  }
`;
const Description = styled.div`
  font-size: 0.9em;
  margin-top: 30px;
  color: white;
  width: 50%;
  opacity: 0.8;
  font-weight: lighter;
  line-height: 1.6;
  z-index: 1;

  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  //top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: ${({ containerHeight }) =>
    containerHeight}px; /* Usar la altura del contenedor */
  object-fit: cover; /* Para que la imagen mantenga su proporción */
  opacity: 0.05;
  z-index: 0; /* Asegurarse de que esté detrás del contenido */
`;
const Spots = styled.div`
  display: flex;
  height: ${({ containerHeight }) => containerHeight}px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 44rem;
  }
`;
const Spot1 = styled.img`
  height: 14rem;
  width: auto;
  align-self: flex-end;
  margin-bottom: 1rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 8.2rem;
  }
`;
const Spot2 = styled.img`
  height: 22rem;
  width: auto;
  margin-top: -2.5rem;
  //align-self: flex-end;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 15rem;
  }
`;
