import React from 'react';
import styled, { keyframes } from "styled-components";
import humanizeDuration from "humanize-duration"
import appContext from "../providers/app/AppContext";
import { DJANGO_API, FRONTEND_URL } from "../utils/config";
import { colors } from "../utils/colors";
import { METHODS, RESPONSE_STATUS } from "../utils/constants";
import Loading from './Loading';
import {
    LabelFieldText,
} from "../components/StyledComponents/StyledComponents";

const defaultSecounds = 120

export default function Resend({ email, action, counterValue=defaultSecounds }) {
    const app = React.useContext(appContext);
    const [isLoading, setIsLoading] = React.useState(true)
    const [counter, setCounter] = React.useState(-1)
    const [counterText, setCounterText] = React.useState('')

    const handleSendCode = (event) => {
        event.preventDefault()
        setIsLoading(true)
        let url = `${DJANGO_API}/users/users/a/send_verification_code/`;
        let data = {
            email: email,
            action: action
        };
        app.axiosRequestApiKey(
            url,
            METHODS.POST,
            (res) => {
                setIsLoading(false)
                setCounter(counterValue)
                window.localStorage.setItem("counter", counterValue)
                setCounterText(humanizeDuration(counterValue * 1000, { language: "es" }))
            },
            data,
            (error) => {
                setIsLoading(false)
                if (error.status === 'failed') {
                    console.log(error.message);
                }
            }
        );
    }

    React.useEffect(() => {
        counter >= 0 && setTimeout(() => {
            setCounter(counter - 1)
            window.localStorage.setItem("counter", counter.toString())
            setCounterText(humanizeDuration(counter * 1000, { language: "es" }))
        }, 1000);
    }, [counter]);

    React.useEffect(() => {
        const stored_counter = window.localStorage.getItem("counter")
        if (stored_counter) {
            setCounter(parseInt(stored_counter))
        }else{
            setCounter(counterValue)
        }
        setIsLoading(false)
    }, []);

    return (
        isLoading ?
            <Loading /> :
            counter >= 0 ?
                <LabelFieldText style={{ textAlign: 'left' }}>{counterText && `Prodrás reenviar en: ${counterText}`} </LabelFieldText> :
                <A href='#' onClick={(event) => { handleSendCode(event) }}>Reenviar</A>

    )
}

const A = styled.a`
color: ${colors.orangeLigth}
`;