import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Opinion from "../CoreLayout/Opinion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import News from "../News";

const CarouselOrganization = ({ organizations }) => {
  const settings = {
    autoplay: true,
    centerMode: true,
    infinite: true,
    centerPadding: "120px",
    slidesToShow: 6,
    speed: 500,
    autoplaySpeed: 2000,
    arrows: false, // Desactivar flechas de navegación
    dots: false,    // Activar indicadores (puntos)
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerPadding: "20px",
        },
      },
    ],
    beforeChange: (current, next) => { },
  };


  return (
    <SliderWrapper>
      <Slider {...settings}>
        {organizations?.map((item, index) => {
          return (
            <Organization key={index}>
              <ImgWrapper>
                <Img src={item.img} />
              </ImgWrapper>
            </Organization>
          );
        })}
      </Slider>
    </SliderWrapper>
  );
};

export default CarouselOrganization;

const SliderWrapper = styled.div`
  width: 100%;
  margin: auto;

  .slick-slide {
    transition: transform 0.3s ease;
  }

  .slick-center .slick-slide {
    transform: scale(1.1);
  }

  .slick-dots {
    bottom: -30px; /* Ajustar posición de los puntos */
  }

  .slick-dots li {
    margin: 0 5px; /* Espaciado entre puntos */
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: black; /* Color de los puntos inactivos */
    opacity: 0.5; /* Hacer los puntos inactivos un poco transparentes */
  }

  .slick-dots li.slick-active button:before {
    opacity: 1; /* Punto activo completamente opaco */
    width: 20px; /* Hacer el punto activo más ancho */
    height: 10px; /* Ajustar la altura del punto activo */
    border-radius: 10px; /* Mantener bordes redondeados */
  }

  .active-opinion {
    transform: scale(1.1);
  }
`;
const Organization = styled.div`
  height: 80px;
  width: 80px;

  margin-bottom: 70px;
`;
const ImgWrapper = styled.div`
  height: 80px;
  width: 80px;
`;
const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-left: 30px;
  margin-right: 30px;
`;