import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Footer from "../components/Footer";
import Header from "../components/Header";
import BestFeatures from "../components/BestFeatures";
import SabiasQue from "../components/SabiasQue";
import Organizations from "../components/Organizations";
import PeopleOpinion from "../components/CoreLayout/PeopleOpinion";

const CoreLayout = () => {
  return (
    <Container>
      <Section id="home">
        <Header />
      </Section>
      <Section id="features">
        <BestFeatures />
      </Section>
      <Section id="sabiasque">
        <SabiasQue />
      </Section>
      <Section id="organizations">
        <Organizations />
      </Section>
      <Section id="people_opinion">
        <PeopleOpinion />
      </Section>
      <Section id="footer">
        <Footer />
      </Section>

      {/* <Outlet /> */}
    </Container>
  );
};

export default CoreLayout;

const Container = styled.div`
  height: 100vh;
  width: 100%;
`;
const Section = styled.div`
  scroll-margin-top: 80px; /* Para compensar el header fijo */
  //padding: 0 70px;
  overflow-y: hidden;
`;
