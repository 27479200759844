import React from 'react';
import styled from 'styled-components';
import NavBarBasic from '../components/NavBar/NavBarBasic';
import { ContainerPageBlank, ContentPageBlank, H2PageBlank, H3PageBlank, ParagraphPageBlank, WraperPageBlank } from '../components/StyledComponents/StyledComponents';

const OurServices = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <ContentPageBlank>
      <NavBarBasic />
      <WraperPageBlank>
        <ContainerPageBlank>
          <H2PageBlank>Servicios</H2PageBlank>
          <ParagraphPageBlank>
            Sabemos lo importante que son los mascotas para ti, por eso, a parte de las funciones que podrás encontrar en la aplicación móvil PetMimo, te brindamos los siguientes servicios en caso de que los necesites:
          </ParagraphPageBlank>
          <H3PageBlank>Servicios de diseño gráfico</H3PageBlank>
          <ParagraphPageBlank>
            Este servicio te permitirá contar con profesionales que podrán ayudarte a crear recursos multimedia con la calidad que buscas de acuerdo al evento. Ya sea crear postales para cumpleaños, editar las fotos de tu album o crear videos inolvidables.
          </ParagraphPageBlank>
          <H3PageBlank>Rescatadores</H3PageBlank>
          <ParagraphPageBlank>
            La red de rescatadores está pensada para que encuentres apoyo en personas que de forma voluntaria comparten su tiempo para ayudarte a encontrar tu mascota perdida. Ponte en contacto con nosotros y te asignaremos personas que estén geográficamente lo más cerca de ti.
          </ParagraphPageBlank>
          <H3PageBlank>Sistema de Reconocimiento Facial para mascotas perdidas</H3PageBlank>
          <ParagraphPageBlank>
            Este servicio es una funcionalidad Premium que podrás contratar desde la aplicación PetMimo. Podemos lograr reconocer la foto de tu mascota perdida en caso de que sea publicada en la aplicación, en grupos de facebook o en diferentes sitios web. En caso de que nuestro Sistema detecte coincidencias, te enviará un mensaje al celular, a tu correo electrónico, o a tu whatsapp.
          </ParagraphPageBlank>
        </ContainerPageBlank>
      </WraperPageBlank>
    </ContentPageBlank>
  );
};

export default OurServices;
const Content = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #F5F5F5

`;
const Wraper = styled.main`
  background-color: #F5F5F5;
  padding: 100px 25%;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 30px;
  color: #333333;
  text-align: justify;
`
const H2 = styled.h2`
  margin-bottom: 50px;
  font-weight: 400;
  font-size: 40px;
  text-transform: uppercase;
`
const H3 = styled.h3`
`
const Parrafo = styled.p`
`