import React from "react";
import Grid from "@mui/material/Grid";
import PhoneView from "../../components/FormFields/PhoneView";
import FieldView from "../../components/FormFields/FieldView";
import moment from "moment";

const PetLostState = ({ lostData, petData }) => {
  console.log("lostData: ", lostData);
  
  let phoneNumber = "";
  if (lostData && lostData.phone_code) {
    phoneNumber += `+${lostData.phone_code}`;
  }
  else if (petData && petData.user_info && petData.user_info.phone_code) {
    phoneNumber += `+${petData.user_info.phone_code}`;
  }
  if (lostData && lostData.phone) {
    phoneNumber += `${lostData.phone}`;
  }
  else if (petData && petData.user_info && petData.user_info.phone) {
    phoneNumber += `${petData.user_info.phone}`;
  }

  let email = "";
  if (lostData && lostData.email) {
    email += `${lostData.email}`;
  }
  else if (petData && petData.user_info && petData.user_info.email) {
    email += `${petData.user_info.email}`;
  }

  return (
    <>
      {phoneNumber !== "" ? (
        <>
          <Grid item xs={12} md={2}>
            <PhoneView field={"Teléfono"} data={phoneNumber} />
          </Grid>
          <Grid item xs={0} md={10} />
        </>
      ) : null}
      {email !== "" ? (
        <Grid item xs={12} md={6}>
          <FieldView field={"Correo electrónico"} data={email} />
        </Grid>
      ) : null}
      {lostData && lostData.description ? (
        <Grid item xs={12} md={6}>
          <FieldView field={"Descripción"} data={lostData.description} />
        </Grid>
      ) : null}
      {lostData && lostData.lost_address ? (
        <Grid item xs={12} md={6}>
          <FieldView field={"Dirección (de última vez vista)"} data={lostData.lost_address} />
        </Grid>
      ) : null}
      {lostData && lostData.lost_date && moment(lostData.lost_date, 'YYYY-MM-DD').isValid ? (
        <Grid item xs={12} md={6}>
          <FieldView field={"Fecha (de última vez vista)"} data={moment(lostData.lost_date, 'YYYY-MM-DD').format('DD/MM/YYYY')} />
        </Grid>
      ) : null}
      {lostData ? (
        <Grid item xs={12} md={6}>
          <FieldView field={"Recompensa"} data={lostData.reward ? "Sí" : "No"} />
        </Grid>
      ) : null}
      {lostData && lostData.reward && lostData.reward_text ? (
        <Grid item xs={12} md={6}>
          <FieldView field={"Detalles de la recompensa"} data={lostData.reward_text} />
        </Grid>
      ) : null}
    </>
  );
};

export default PetLostState;
