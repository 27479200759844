export const AUTO_REFRESH_INTERVAL = 5000;

export const PETMIMO_TOKEN_KEY = 'access_token';
export const FACEBOOK_TOKEN_KEY = 'facebook_access_token';
export const GOOGLE_TOKEN_KEY = 'google_access_token';
export const USER_EMAIL_KEY = 'email';
export const USER_ID_KEY = 'id';
export const SOCIAL_PHOTO = 'social_photo';
export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  UPLOAD: 'UPLOAD',
  PATCH:'PATCH'
};
export const SHARED_PREFERENCES_NAME = 'petmimoSharedPrefs';
export const KEYCHAIN_SERVICE_NAME = 'petmimoKeychain';
export const LANGUAGES = {
  es: 'es',
  en: 'en',
  pt: 'pt',
};
export const LANGUAGES_JSONS = {
  es: {id: 1, name: 'es'},
  en: {id: 2, name: 'en'},
  pt: {id: 3, name: 'pt'},
};
export const LANGUAGES_IDS = {
  es: 1,
  en: 2,
  pt: 3,
};
export const LANGUAGES_LOCALES = {
  es: 'es_UY',
  en: 'en_US',
  pt: 'pt_BR',
};
export const PREFERENCES_IDS = {
  SPECIES_RACES:1,
  CATEGORIES:2,
  LANGUAGE: 3,
  NOTIFICATION:4,
  PRIVACY:5,
  ADVERTISING:6,

};
export const DATA_SECTIONS_IDS = {
  SWAP:'Swap',
  LOST:'Lost',
  ADOPTION: 'Adoption',
  LOOKING_PARTNER:'Looking_Partner',
  DECEASED:'Deceased',

};
export const PRIVACY_IDS = {
  SHOW_PHOTO_PERFIL:1,
  EMAIL_VISIBILITY:2,
  PHONE_NUMBER_VISIBILITY: 3,
  LIST_CONTACT_VISIBILITY:4,
  FOLLOW_PERSON_VISIBILITY:5,
  SEARCH_PERFIL_BY_EMAIL:6,
  SEARCH_PERFIL_BY_PHONE_NUMBER:7,

};
export const SELECTED_LANGUAGE_KEY = 'selected_language';
//export const CHUNK_SIZE = 2048 * 30;
export const CHUNK_SIZE = 1024 * 1024 * 10;
export const MAX_SIZE = 1024 * 1024 * 100;
export const MAX_DURATION = 30;
export const IMAGE_PICKER_MODES = {
  PHOTO: 'photo',
  VIDEO: 'video',
  MIXED: 'mixed',
};
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const RESPONSE_STATUS = {
  failure: "failed",
};
