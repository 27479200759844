import React from 'react'
import Noticias from './Noticias'
import Organizations from './Organizations'
import NuestroEquipo from './NuestroEquipo'
import SabiasQue from './SabiasQue'

function Home() {
    return (
        <React.Fragment>            
            <Noticias/>            
            <SabiasQue/>
            <Organizations />
            <NuestroEquipo/>            
        </React.Fragment>
    )
}

export default Home

