import React from "react";
import {
  DataFieldText,
  FieldContainer,
  FieldSpaceBetweenContainer,
  LabelFieldText,
  PhoneImage,
} from "../StyledComponents/StyledComponents";

const PhoneView = (props) => {
  return (
    <FieldContainer>
      <LabelFieldText>{props.field}</LabelFieldText>
      <FieldSpaceBetweenContainer>
        <DataFieldText>{props.data}</DataFieldText>
        <a href={`tel:${props.data}`}>
          <PhoneImage />
        </a>
      </FieldSpaceBetweenContainer>
    </FieldContainer>
  );
};

export default PhoneView;
