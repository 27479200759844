import React from "react";
import { useParams } from "react-router-dom";
import appContext from "../../providers/app/AppContext";
import { DJANGO_API, FRONTEND_URL } from "../../utils/config";
import { METHODS, RESPONSE_STATUS } from "../../utils/constants";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import _ from "lodash";
import {
  Divisor,
  LocationContainer,
  LocationImage,
  LocationText,
  LostMessageText,
  MarginContainer,
  PetImage,
  PetTitle,
  PetTitleContainer,
  RoundedContainer,
  StateContainer,
  StateLabel,
  StateText,
} from "../../components/StyledComponents/StyledComponents";
import PetLostState from "./PetLostState";
import Loading from "../Loading/Loading";

const PetPublicProfile = (props) => {
  const app = React.useContext(appContext);
  let { petId } = useParams();
  console.log(petId);

  const [errorMessage, setErrorMessage] = React.useState("");
  const [petData, setPetData] = React.useState(null);
  const [lostData, setLostData] = React.useState(null);
  const [scanData, setScanData] = React.useState(null);
  const [petPic, setPetPic] = React.useState(
    "/../images/Profile/background-pet.jpg"
  );
  const [currentLocation, setCurrentLocation] = React.useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getPetInfo();
    getCurrentLocation();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (currentLocation && scanData) {
      sendCurrentLocation();
    }
    // eslint-disable-next-line
  }, [currentLocation, scanData]);

  const getPetInfo = () => {
    let url = DJANGO_API + `/pet/pet/a/by_qr_code_id/`;
    let data = {
      public_url: FRONTEND_URL + petId,
    };
    app.axiosRequestApiKey(
      url,
      METHODS.POST,
      (res) => {
        console.log(res);
        if (res && res.pet) {
          processPetData(res.pet);
        }
        if (res && res.scanevent) {
          setScanData(res.scanevent);
        }
      },
      data,
      (err) => {
        console.log(err);
        if (err.status === RESPONSE_STATUS.failure && err.message) {
          setErrorMessage(err.message);
        }
      }
    );
  };

  const getPetPic = (url) => {
    app.getFileFromObjectStorage(url, savePetPicPublicUrl);
  };

  const savePetPicPublicUrl = (publicUrl) => {
    console.log("savePetPicPublicUrl: ", publicUrl);
    setPetPic(publicUrl);
  };

  const processPetData = (petData) => {
    let lostStatus = getLostStatusData(petData);
    let pic = null;
    if (lostStatus && lostStatus.photo) {
      pic = lostStatus.photo;
    } else if (petData && petData.pic) {
      pic = petData.pic;
    }
    getPetPic(pic);
    setLostData(lostStatus);
    setPetData(petData);
  };

  const getLostStatusData = (petData = null) => {
    let lostStatus = null;
    if (petData && petData.states_dict && petData.states_dict.length > 0) {
      _.forEach(petData.states_dict, (item) => {
        if (
          item &&
          item.petdata &&
          item.petdata.active === true &&
          item.petdata.datasection === 1 &&
          item.state
        ) {
          lostStatus = item.state;
        }
      });
    }
    return lostStatus;
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      if (position && position.coords) {
        console.log("position: ", position);
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ latitude, longitude });
      }
    });
  };
  
  const sendCurrentLocation = () => {
    if (currentLocation && scanData && scanData.id) {
      let url = DJANGO_API + `/pet/scanevents/${scanData.id}/`;
      let data = {
        latitude: currentLocation.latitude,
        length: currentLocation.longitude,
      };
      app.axiosRequestApiKey(
        url,
        METHODS.PATCH,
        (res) => {
          console.log(res);          
        },
        data,
        (err) => {
          console.log(err);
          if (err.status === RESPONSE_STATUS.failure && err.message) {
            setErrorMessage(err.message);
          }
        }
      );
    }
  };

  let ready = (petData && petPic) || (errorMessage && errorMessage.length > 0) === true;  

  return (
    ready ? (
      <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        {petData && petPic ? (
          <Grid item xs={12}>
            <PetImage src={petPic} />
            <RoundedContainer>
              <MarginContainer>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <PetTitleContainer>
                      <PetTitle>{petData.name}</PetTitle>
                      <LocationContainer>
                        <LocationImage />
                        {petData.state_dict && petData.state_dict.name ? (
                          <LocationText>{petData.state_dict.name}</LocationText>
                        ) : null}
                        {petData.country_dict && petData.country_dict.name ? (
                          <LocationText>
                            {", " + petData.country_dict.name}
                          </LocationText>
                        ) : null}
                      </LocationContainer>
                    </PetTitleContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <StateContainer>
                      {petData.states_dict &&
                        petData.states_dict.map((state, index) => {
                          return (
                            state["petdata"].active && (
                              <StateLabel key={index}>
                                <StateText>
                                  {state[
                                    "petdata"
                                  ].datasection_dict.name.toUpperCase()}
                                </StateText>
                              </StateLabel>
                            )
                          );
                        })}
                    </StateContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <Divisor />
                  </Grid>
                  <Grid item xs={12}>
                    <LostMessageText>
                      Si escaneaste este código QR, es porque quizás estoy
                      perdida/o. Contacta a mi dueño.
                    </LostMessageText>
                  </Grid>
                  <PetLostState lostData={lostData} petData={petData} />
                </Grid>
              </MarginContainer>
            </RoundedContainer>
          </Grid>
        ) : errorMessage && errorMessage.length > 0 ? (
          <Grid item xs={12}>
            <PetImage src={null} />
            <RoundedContainer>
              <MarginContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PetTitleContainer>
                      <PetTitle>{errorMessage}</PetTitle>
                    </PetTitleContainer>
                  </Grid>
                </Grid>
              </MarginContainer>
            </RoundedContainer>
          </Grid>
        ) : null }
      </Grid>
    </Box>
    )
    :
    (
      <Loading />
    )
  );
};

export default PetPublicProfile;
