import React from 'react';
import NavBarBasic from '../components/NavBar/NavBarBasic';
import { ContainerPageBlank, ContentPageBlank, H2PageBlank, H3PageBlank, Li, ParagraphPageBlank, Ul, WraperPageBlank } from '../components/StyledComponents/StyledComponents';

const OurProducts = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <ContentPageBlank>
      <NavBarBasic />
      <WraperPageBlank>
        <ContainerPageBlank>
          <H2PageBlank>Productos</H2PageBlank>
          <H3PageBlank>Placa de Identificación con QR</H3PageBlank>
          <ParagraphPageBlank>
            La placa de identificación con QR permite asignar a tu mascota un identificador único dentro de nuestra plataforma.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            Este producto es útil para en caso de pérdida o extravío de la mascota, seas contactado/a facilmente por quien escanee el código QR, ya sea a través de la app de PetMimo, por email o llamándote por teléfono.
          </ParagraphPageBlank>

          <ParagraphPageBlank>
            En la aplicación PetMimo, también podrás ver en el mapa la ubicación donde está tu mascota cuando el QR es escaneado. Para esto se requiere que quien escanee el QR tenga activa la ubicación GPS de su teléfono móvil y la comparta contigo. La información estará disponible cuando recibas notificación en la app, avisando que tu mascota fue encontrada.
          </ParagraphPageBlank>
          <ParagraphPageBlank>
            Esta placa de identificación con QR tiene varias ventajas sobre las placas tradicionales:
            <Ul>
              <Li>Podrás cambiar tus datos de contacto en cualquier momento.</Li>
              <Li>
                Actualizar información de la mascota que consideres importante para un mejor cuidado. Por ejemplo, si tu mascota tiene una reacción adversa a ciertos alimentos que le provocan alergias, seria útil que quien la encuentre conozca esto de antemano.
              </Li>
              <Li>
              </Li>
              Si tu mascota se pierde, quizas este un poco asustada y seria mas dificil poder acercarse para leer algún teléfono de contacto. Con nuestra placa de identificación, los datos podrán ser obtenidos escaneando el QR a cierta distancia.
              <Li>
                De forma similar, si quien encuentra tu mascota por alguna razón teme acercarse (ejemplo: debido al tamaño o raza de algunos perros), el poder obtener tus datos de contacto desde cierta distancia seguirá siendo una ventaja.
              </Li>
            </Ul>
          </ParagraphPageBlank>
        </ContainerPageBlank>
      </WraperPageBlank>
    </ContentPageBlank>
  );
};

export default OurProducts;