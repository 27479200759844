import React from "react";
import styled from "styled-components";
import News from "./News";
import CustomCarousel from "./Carousel/CustomCarousel";
import { colors } from "../utils/colors";
import CarouselNews from "./Carousel/CarouselNews";
import { ContainerAnyDisplay } from "./StyledComponents/StyledComponents";
const ImageRombus = "/images/Noticias/notice1.png";

function SabiasQue() {
  const [news, setNews] = React.useState([
    {
      id: 1,
      title: "Los animales sí sienten emociones, dolor y placer",
      description:
        "Desde las aves hasta los mamíferos -pasando por los peces y hasta los moluscos- los animales sienten porque tienen circuitos y estructuras neurológicas que generan conciencia, aunque a los humanos nos cueste interpretar lo que sienten sólo con observarlos.",
      image:
        "https://certifiedhumanelatino.org/wp-content/uploads/2019/02/CERTIFIED-HUMANE_Post-blog-1.jpg",
      url: "https://certifiedhumanelatino.org/los-animales-sienten-emociones-dolor-placer/#:~:text=Ser%20conscientes%20de%20que%20los,paso%20para%20garantizar%20su%20bienestar.",
    },
    {
      id: 2,
      title: "¿QUÉ SUEÑAN LOS PERROS?",
      description:
        "Según la confirmación de un estudio de una universidad americana, se demostró que los perros sueñan como lo hacemos los humanos, recordando cosas que hacen en su día a día, como la relación con sus propietarios, la comida o los paseos.",
      image:
        "https://gestion.portalbiesa.com/redaccio/arxius/imatges/202207/770_1658237010blog_post_suenan_perros_1.jpg",
      url: "https://www.desparasitaatumascota.es/blog/65/que-suenan-los-perros",
    },
    {
      id: 3,
      title: "¿PORQUÉ LOS GATOS ARAÑAN Y MUERDEN COSAS?",
      description:
        "Si tu gato tiene la costumbre de masticar o arañar algunas prendas u objetos de tu hogar, puede ser por diferentes razones. ¿Por qué los gatos arañan las cosas?",
      image:
        "https://gestion.portalbiesa.com/redaccio/arxius/imatges/201906/770_1560758048blog_post_porque-los-gatos-aranan_y_muerden_cosas_1.jpg",
      url: "https://www.desparasitaatumascota.es/blog/43/porqu-los-gatos-aranan-y-muerden-cosas",
    },
    {
      id: 4,
      title: "30 curiosidades sobre los perros",
      description:
        "¿Sabes que los perros son capaces de oler cuándo estamos enfermos? ¿Y de que existe una raza de perro que no ladra? Si los perros te enloquecen, no puedes quedarte con las ganas de conocer estas curiosidades.",
      image:
        "https://imagenes.muyinteresante.com/files/image_990_557/uploads/2024/01/11/659fc12f26e31.jpeg",
      url: "https://www.muyinteresante.com/mascotas/6026.html",
    },
    {
      id: 5,
      title: "Las 10 mascotas más raras del mundo",
      description:
        "Si estás considerando la idea de tener una mascota pero no te has decidido, continúa leyendo este artículo de ExpertoAnimal sobre las 10 mascotas más raras del mundo.",
      image:
        "https://t1.ea.ltmcdn.com/es/posts/3/8/5/las_10_mascotas_mas_raras_del_mundo_22583_600.webp",
      url: "https://www.expertoanimal.com/las-10-mascotas-mas-raras-del-mundo-22583.html",
    },
  ]);

  return (
    <ContainerAnyDisplay>
      <Wrap>
        <Section>
          <Name>¿Sabías que?</Name>
          <Line />
        </Section>
        <CarouselNews
          news={news}
          indicator={true}
          colorIndicator={colors.grayLigth}
        />

        {/* <CustomCarousel listData={news}>
        {news?.map(({ title, description, image, url }, index) => (
          <News
            title={title}
            description={description}
            image={image}
            url={url}
            key={index}
          />
        ))}
      </CustomCarousel> */}
      </Wrap>
    </ContainerAnyDisplay>
  );
}

export default SabiasQue;

const Wrap = styled.div`
  height: 45rem;
  align-items: center;
  display: flex;
  margin-bottom: 5rem;
  flex-direction: column;
  margin-top: 1rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 50rem;
  }
`;
const Section = styled.div`
  margin-top: 3rem;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-bottom: 30px;
    align-self: center;
  }
`;
const Name = styled.h2`
  font-size: 18px;
  font-weight: 800;
  margin-right: 120px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-right: 0;
  }
`;
const Line = styled.div`
  height: 3px;
  width: 140px;
  margin-left: -75px;
  background-color: ${({ theme }) => theme.colors.orangeDark};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-left: 10px;
    width: 88px;
  }
`;
const ControlNews = styled.div`
  display: flex;
  width: 100px;
  height: 25px;
  justify-content: space-between;
  //flex-direction: column;
  //s z-index: 99;
  margin-bottom: 0;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 170px;
  }
  //align-self: flex-end;
`;
const IconControl = styled.img`
  height: 100%;
  width: 25px;
`;
