import React from 'react';
import styled, {keyframes} from 'styled-components';
import _ from 'lodash';
import { AUTO_REFRESH_INTERVAL } from '../utils/constants';

function Noticias() {
  const [activeItem, setActiveItem] = React.useState(0);
  //const [news, setNews] = React.useState(getStaticNews());   
  const news = getStaticNews();   

  const cleanup = (intervalId) => {
    clearInterval(intervalId);
  };

  const arrowHandler = (index) => {
    let finalIdex = index > news.length - 1 ? 0 : index < 0 ? news.length - 1 : index;
    setActiveItem(finalIdex);
  };

  React.useEffect(() => {
    const onChange = (index) => {
      let finalIdex = index > news.length - 1 ? 0 : index < 0 ? news.length - 1 : index;
      setActiveItem(finalIdex);
    }; 
    const incrementActiveItem = () => {
      onChange(activeItem + 1);
    };
    const createInterval = () => {
      return setInterval(incrementActiveItem, AUTO_REFRESH_INTERVAL);
    };
    const intervalId = createInterval();
    // Specify how to clean up after this effect:
    return () => cleanup(intervalId);
  }, [activeItem, news.length]);    

    return (
        <Wrap>
            <Image src={news && news[activeItem] ? news[activeItem].img : ""}>
                <Items>
                  {news && news.length > 0 ? news.map((_, index) => {
                    return index === activeItem ? <li style={{color: "#C15810"}}>.</li> : <li>.</li>;
                  }) : null}
                </Items>
                <Contador>
                    <NumeroMayor>{_.padStart(activeItem + 1, 2, '0')}</NumeroMayor>
                    <NumeroTotal>/ {_.padStart(news.length, 2, '0')}</NumeroTotal>
                </Contador>
            </Image>
            <Noticia>
                <Info>
                    <Header>Noticias</Header>
                    <Title>{news && news[activeItem] ? news[activeItem].title : ""}</Title>
                    <Text>{news && news[activeItem] ? news[activeItem].abstract : ""}</Text>
                    <WrapLinkDate>
                      <Date>{news && news[activeItem] ? news[activeItem].date : ""}</Date>
                      <Link href={news && news[activeItem] ? news[activeItem].link : "/"}>Ver más</Link>              
                    </WrapLinkDate>
                </Info>
                <ArrowWrap>
                    <ArrowLeft 
                        onClick={() => arrowHandler(activeItem - 1)}
                        src='images/Icons/ArrowGrayLeft.png' 
                    />
                    <ArrowRight 
                        onClick={() => arrowHandler(activeItem + 1)}
                        src='images/Icons/ArrowGrayRight.png' 
                    />
                </ArrowWrap>
            </Noticia>
        </Wrap>
    )
}

export default Noticias

const Wrap = styled.div`
    width: 100vw;
    height: 90vh;
    display: flex;
`
// Create the keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Image = styled.div`
    width: 55vw;
    height: 90vh;
    background-size: cover ;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.src ? props.src : "/images/Noticias/Noticia 1.jpg"});    
    display: flex;
    padding: 50px;
    justify-content: space-between;    
`
const Noticia = styled.div`
    padding: 60px;
    padding-right: 5%;
    width: 50vw;
    height: 90vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: justify;
`
const Items = styled.div`
    display: flex;
    font-size: 7em;
    list-style-type: none;
    margin-top: -80px;
    gap: 20px;
`
const Contador = styled.div`
    align-self: flex-end;
    text-align: right;
`
const NumeroMayor = styled.p`
    font-size: 9em;
    font-weight: 500;
    color: #C15810;
    margin-bottom: -20px;
`
const NumeroTotal = styled.p`
    font-size: 4em;
    font-weight: bold;
    color: #333333;
    margin-right: 10px;
`
const Info = styled.div`
`
const Header = styled.h1`
    color: #333333;
    font-size: 12em;
    font-weight: 100;
    margin-left: -130px;
    margin-top: -50px;
    //animation: ${fadeIn} 2s;
`
const Title = styled.h3`
    font-size: 2em;
    color: #333333;
    font-weight: 500;
    margin-top: 50px;
    //animation: ${fadeIn} 2s;
`
const Text = styled.p`
    color: #333333;
    font-size: 1.8em;
    margin-top: 20px;
    //animation: ${fadeIn} 2s;
`
const WrapLinkDate = styled.div`
  display: flex;
  margin-top: 60px;
  gap: 20px;
`
const Link = styled.a`
    font-weight: 500;
    color: #C15810;
    font-size: 1.2em;
    text-decoration: none;
`
const Date = styled.p`
    color: #333333;
    font-size: 1.2em;
    font-weight: 500;
`
const ArrowWrap = styled.div`
    display: flex;
    gap: 80px;
`
const ArrowLeft = styled.img`
    height: 40px;
    width: 40px;
`
const ArrowRight = styled(ArrowLeft)`
`
const getStaticNews = () => {
  return [
    {
      title: "¿Qué significa que los animales pasarán a ser «seres sintientes»?",
      img: "/images/Noticias/Noticia_1.jpg",
      abstract: "La Comisión de Justicia del Congreso de los Diputados ratificará este martes la Ponencia que prevé la modificación de varias leyes y que en la práctica conllevará que legalmente los animales dejarán de ser bienes muebles o cosas.",
      date: "05/10/2021",
      link: "https://www.abc.es/sociedad/abci-significa-animales-pasaran-seres-sintientes-202110051221_noticia.html",
    },
    {
      title: "En 2022 será obligatorio que tu perro o gato tenga un DNI animal",
      img: "/images/Noticias/Noticia_5.jpg",
      abstract: "La Dirección General de Derechos de los Animales tiene previsto implantar en 2022 un DNI para las mascotas.",
      date: "21/10/2021",
      link: "https://www.elperiodico.com/es/sociedad/20211021/2022-obligatorio-dni-perro-gato-12327065",
    },  
    {
      title: "El misterioso Equipo A que rescató a los perros amenazados por la lava en La Palma entrega a los animales",
      img: "/images/Noticias/Noticia_8.jpg",
      abstract: "El Ministerio de Derechos Sociales y Agenda 2030 afirma que los canes están bien pero que van a ser revisados por veterinarios.",
      date: "26/10/2021",
      link: "https://elpais.com/sociedad/2021-10-26/el-misterioso-equipo-a-que-rescato-a-los-perros-amenazados-por-la-lava-en-la-palma-entrega-a-los-animales.html",
    },
    {
      title: "Rescatan a un perro que encuentran nadando solo en medio del océano",
      img: "/images/Noticias/Noticia_7.jpg",
      abstract: "El video del rescate del animal flotando mar adentro se viralizó; sus salvadores resolvieron además el misterio de cómo había llegado la mascota a ese lugar.",
      date: "29/10/2021",
      link: "https://www.lanacion.com.ar/lifestyle/rescatan-a-un-perro-que-encuentran-nadando-solo-en-medio-del-oceano-nid29102021/",
    },
  ];
}