import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Opinion from "../CoreLayout/Opinion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import News from "../News";

const CarouselNews = ({ news, indicator = false, colorIndicator }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const renderIndicator = (index, label) => {
    const indicatorStyle = {
      background: colorIndicator || "#333333",
      width: activeIndex === index ? '30px' : '10px',
      height: '10px',
      borderRadius: '20px',
      margin: activeIndex === index ? '0px' : '0 10px',

      cursor: 'pointer',
      transition: 'all 0.3s ease',
    };

    return (
      <li
        style={indicatorStyle}
        //onClick={clickHandler}
        //onKeyDown={clickHandler}
        value={index}
        key={index}
        role="button"
        tabIndex={0}
      // aria-label={`${label} ${index + 1}`}
      //aria-selected={isSelected}
      />
    );
  };
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    arrows: false, // Desactivar flechas de navegación
    dots: indicator,    // Activar indicadores (puntos)
    customPaging: renderIndicator,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "5px",
          dots: true,
          adaptiveHeight: true,
          centerMode: true,
          slidesPerRow: 1
        },
      },
    ],
    beforeChange: (current, next) => setActiveIndex(next),
  };


  return (
    <SliderWrapper>
      <Slider {...settings}>
        {news.map(({ title, description, image, url }, index) => (
          <News
            title={title}
            description={description}
            image={image}
            url={url}
            key={index}
          />
        ))}
      </Slider>
    </SliderWrapper>
  );
};

export default CarouselNews;

const SliderWrapper = styled.div`
  width: 100%;
  margin: auto;

  .slick-slide {
    transition: transform 0.3s ease;
  }

  .slick-center .slick-slide {
    transform: scale(1.1);
  }

  .slick-dots {
    bottom: -30px; /* Ajustar posición de los puntos */
  }

  .slick-dots li {
    margin: 0 5px; /* Espaciado entre puntos */
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: black; /* Color de los puntos inactivos */
    opacity: 0.5; /* Hacer los puntos inactivos un poco transparentes */
  }

  .slick-dots li.slick-active button:before {
    opacity: 1; /* Punto activo completamente opaco */
    width: 20px; /* Hacer el punto activo más ancho */
    height: 10px; /* Ajustar la altura del punto activo */
    border-radius: 10px; /* Mantener bordes redondeados */
  }

  .active-opinion {
    transform: scale(1.1);
  }
`;
