import React from 'react'
import styled from 'styled-components'

function NuestroEquipo() {
    return (
        <Wrap>
            <Info>
                <Title>Nuestro Equipo</Title>
                <Description>Somos un equipo de jóvenes profesionales apasionados por las mascotas y con alta especialización en el desarrollo de software.</Description>
            </Info>
            <Pictures>
                <Person>
                    <Img src='/images/Equipo/Edy1.jpg' />
                    <Name>EDISNEL CARRAZANA</Name>
                    <Function>BACKEND</Function>
                </Person>
                <Person>
                    <Img src='/images/Equipo/Osma1.jpg' />
                    <Name>OSMAILY DURÁN</Name>
                    <Function>QA TESTER</Function>
                </Person>
                <Person>
                    <Img src='/images/Equipo/Ale1.jpg' />
                    <Name>ALEJANDRO MEDINA</Name>
                    <Function>FRONTEND</Function>
                </Person>
                <Person>
                    <Img src='/images/Equipo/Carlos1.jpg' />
                    <Name>CARLOS HERNÁNDEZ</Name>
                    <Function>UI DEVELOPER</Function>
                </Person>
            </Pictures>
        </Wrap>
    )
}

export default NuestroEquipo

const Wrap = styled.div`
    width: 100vw;
    // height: 100vh;
    display: flex;
    flex-direction: column;
`
const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
`
const Title = styled.div`
    font-size: 3em;
    font-weight: 200;
`
const Description = styled.div`
    font-size: 1.5em;
    margin-top: 30px;
    font-weight: 300;
    width: 50%;
    text-align: center;
`
const Pictures = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 80px;
    gap: 100px;
`
const Person = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Img = styled.img`
    height: 180px;
    width: 180px;
    border-radius: 50%;
`
const Name = styled.div`
    font-size: 1.5em;
    margin-top: 20px;
`
const Function = styled.div`
`